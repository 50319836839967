import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const Generic = props => (
  <Layout>
    <Helmet>
      <title>Thanh Le College, School of Cosmetology - Guest Services</title>
      <meta name="description" content="Guest Services" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Student Services Performed</h1>
          </header>
          <p>
            Come visit our college for beauty services performed by our
            professionally trained students at discounted prices. Our students
            are well trained and will provide you with qualify services all
            under the watchful eye of our industry experienced intructors.
          </p>
          <div className="grid-wrapper" />
        </div>
      </section>
    </div>
  </Layout>
)

export default Generic
